<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { GameDistrictId } from '~/model/GameDistrict/GameDistrict'
  import { useGameProgressStayStore } from '~/store/progress/GameProgressStay'
  import { navigateTo } from '#imports'
  import useGameDistrict from '~/composables/GameDistrict/GameDistrict'
  import useTranslation from '~/composables/Translation/Translation'

  interface Props {
    districtId: GameDistrictId
  }

  const props = defineProps<Props>()
  const { t } = useTranslation()
  const gameStayProgressStore = useGameProgressStayStore()
  const { toGameDistrict } = useGameDistrict()

  const { unlockDistrict } = gameStayProgressStore
  const { isActiveMode, isDistrictUnlockAvailable, remainingNecessaryTrouvailleCount } =
    storeToRefs(gameStayProgressStore)

  const requestUnlock = () => {
    unlockDistrict(props.districtId)
    navigateTo(toGameDistrict(props.districtId))
  }

  if (!isActiveMode.value) {
    throw new Error(`Wrong game mode detected!`)
  }
</script>

<template>
  <div class="c-action-unlock-district u-typography-root">
    <template v-if="isDistrictUnlockAvailable">
      <p class="c-action-unlock-district__description u-reset">
        {{ t('list-district.unlock-available') }}
      </p>

      <div class="c-action-unlock-district__btn">
        <button class="btn btn--medium btn--primary" @click="requestUnlock">
          {{ t('list-district.action-unlock.label') }}
        </button>
      </div>
    </template>
    <template v-else>
      <p class="c-action-unlock-district__description u-reset">
        {{
          t('list-district.unlock-unavailable', { remaining: remainingNecessaryTrouvailleCount })
        }}
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/base/typography/typography' as type;

  .c-action-unlock-district__description {
    @include type.copy-small;
    display: block;
  }

  .c-action-unlock-district__btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
</style>
